'use client'
import { FC, useEffect } from 'react'
import { useUpdateSearchParams } from '@/hooks/useUpdateSearchParams'
import { FilterRealEstateParams } from '@/core/real-estate/typings'

interface ParamsUpdaterProps {
  filters: FilterRealEstateParams | null
}

const ParamsUpdater: FC<ParamsUpdaterProps> = ({ filters }) => {
  const { updateSearch } = useUpdateSearchParams()

  useEffect(() => {
    if (filters) {
      const group = filters.group && filters.group.length > 0 ? filters.group.join(',') : undefined
      const states =
        filters.states && filters.states.length > 0 ? filters.states.join(',') : undefined

      updateSearch({
        maxDistance: filters.maxDistance,
        lng: filters.coordinates[0],
        lat: filters.coordinates[1],
        type: filters.contractType,
        group,
        states,
        sizeMtsMin: filters.size?.mtsMin,
        sizeMtsMax: filters.size?.mtsMax,
        priceMin: filters.price?.min,
        priceMax: filters.price?.max
      })
    }
  }, [filters, updateSearch])

  return null
}

export default ParamsUpdater
