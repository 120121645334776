'use client'
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  Skeleton,
  StackDivider,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import React, { FC, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CONTENT_HEIGHT,
  DEFAULT_RADIUS,
  MAP_DEBOUNCE,
  MEXICO_CITY_COORDINATES,
  SEARCHBAR_HEIGHT
} from '@/constants'
import {
  AllowedPrices,
  AllowedSizes,
  ContractType,
  MapLocation,
  PageSizeOptions,
  RealEstateLightDTO,
  RealEstateSortOptions
} from '@/core/real-estate/typings'
import { usePaginate } from '@/hooks/usePaginate'
import { MapCenter } from '@/core/typings'
import RealEstateList from '@/app/(public)/components/RealEstateList'
import { Action, ReducerActionType, useSetReset } from '@/hooks/useSetReset'
import { Dropdown } from '@/app/components/common/Dropdown'
import { MultiSelectDropdown } from '@/app/components/common/MultiSelectDropdown/MultiSelectDropdown'
import { AggregatePaginateResult } from 'mongoose'
import { parseSortObject } from '@/utils/parseSortObject'
import AddressSearch from '@/app/components/common/AddressSearch'
import { SizePopoverFilterWrapper } from '@/app/components/common/SizePopoverFilterWrapper'
import { PricePopoverFilterWrapper } from '@/app/components/common/PricePopoverFilterWrapper'
import ParamsUpdater from '@/app/(public)/components/ParamsUpdater'
import { useLoadingToast } from '@/hooks/useLoadingToast'
import { ScrollDragContainer } from '@/app/(public)/components/ScrollDragContainer'
import { getPlaceTypeRadius, PlaceType } from '@/app/components/common/autocomplete/typings'
import ToolboxMenuItem from '@/app/components/common/ToolboxMenuItem'
import { TfiMoreAlt } from 'react-icons/tfi'
import { RadiusSelector } from '@/app/components/common/RadiusSelector'
import CheckboxSelector from '@/app/components/CheckboxSelector'
import { SizeFilterWrapper } from '@/app/components/common/SizeFilterWrapper'
import { PriceFilterWrapper } from '@/app/components/common/PriceFilterWrapper'
import {
  gtmEvent,
  MapInteraction,
  SearchResultsMetadata,
  UserSearch,
  UserSearchResetClick
} from '@/utils/gtm'
import { v4 as uuidv4 } from 'uuid'
import { FaThList } from 'react-icons/fa'
import { IoMdMap } from 'react-icons/io'
import { useInitialFilters } from '@/hooks/useInitialFilters'
import dynamic from 'next/dynamic'
import debounce from 'lodash/debounce'
import { MapData } from '@/app/components/common/CustomMap/typings'
import { FilterItem } from '@/app/components/drawers/typings'
import { RealEstateGroupKeys, useFilters } from '@/hooks/useFilters'

const MapWithMarkers = dynamic(
  () => import('@/app/components/common/CustomMap/index').then((mod) => mod.MapWithMarkers),
  {
    loading: () => <Skeleton height="100%" width="100%" />,
    ssr: false
  }
)

const SearchFiltersDrawer = dynamic(() => import('@/app/components/drawers/SearchFiltersDrawer'))

const TOAST_ID = 'map-search'

interface HomeSearchProps {
  deviceType: 'desktop' | 'mobile'
}

const HomepageSearch: FC<HomeSearchProps> = ({ deviceType }) => {
  const isInitialLoad = useRef(true)
  const isProgrammaticZoomUpdate = useRef(false)

  const isMobile = deviceType === 'mobile'
  const { isOpen: showMap, onToggle: toggleMap } = useDisclosure({
    defaultIsOpen: !isMobile
  })

  const initialFilters = useInitialFilters()

  const [mapInfo, dispatchMapInfo] = useSetReset<MapCenter & { radius: number | null }>({
    lng: initialFilters?.coordinates?.[0] ?? MEXICO_CITY_COORDINATES[0],
    lat: initialFilters?.coordinates?.[1] ?? MEXICO_CITY_COORDINATES[1],
    radius: initialFilters?.maxDistance ?? (isMobile ? DEFAULT_RADIUS : null)
  })

  const {
    dispatchSizeFilter,
    dispatchPriceFilter,
    filters,
    setContractTypeFilter,
    setGroupFilter,
    resetSelectedGroups,
    statesFilter,
    states,
    setStatesFilter,
    resetSelectedStates,
    contractTypeFilter,
    realEstateGroups,
    groupFilter,
    sizeFilter,
    priceFilter,
    handleContractTypeOptionChange
  } = useFilters({ initialFilters, mapInfo })

  const debouncedDispatchMapInfo = useMemo(
    () =>
      debounce((payload: Action<MapCenter & { radius: number }>) => {
        dispatchMapInfo(payload)
      }, MAP_DEBOUNCE),
    [dispatchMapInfo]
  )

  const handleMapUpdate = useCallback(
    ({ center, radius }: MapData) => {
      if (isInitialLoad.current && initialFilters?.coordinates && initialFilters?.maxDistance) {
        isInitialLoad.current = false
        return
      }

      if (isProgrammaticZoomUpdate.current) {
        isProgrammaticZoomUpdate.current = false
        return
      }

      if (center && radius) {
        const finalLat = center.lat()
        const finalLng = center.lng()

        if (mapInfo.lat === finalLat && finalLng === mapInfo.lng && radius === mapInfo.radius) {
          return
        }

        debouncedDispatchMapInfo({
          type: ReducerActionType.Set,
          payload: {
            lat: finalLat,
            lng: finalLng,
            radius
          }
        })
      }
    },
    [debouncedDispatchMapInfo, mapInfo, initialFilters]
  )

  useEffect(() => {
    if (!initialFilters || !isInitialLoad.current) return

    dispatchMapInfo({
      type: ReducerActionType.Set,
      payload: {
        lng: initialFilters.coordinates?.[0] ?? MEXICO_CITY_COORDINATES[0],
        lat: initialFilters.coordinates?.[1] ?? MEXICO_CITY_COORDINATES[1],
        radius: initialFilters.maxDistance ?? (isMobile ? DEFAULT_RADIUS : null)
      }
    })
  }, [initialFilters, dispatchMapInfo, isMobile])

  const [zoom, setZoom] = useState(PlaceType.Country)

  useEffect(() => {
    if (!initialFilters?.maxDistance || !isInitialLoad.current) return

    isProgrammaticZoomUpdate.current = true

    const calculateZoomFromDistance = (distance: number): number => {
      if (distance > 1000000) return 4
      if (distance > 500000) return 6
      if (distance > 100000) return 8
      if (distance > 50000) return 10
      if (distance > 10000) return 12
      return 14
    }

    const zoomLevel = calculateZoomFromDistance(initialFilters.maxDistance)
    setZoom(zoomLevel)
  }, [initialFilters])

  const {
    fetch: fetchPaginated,
    data: paginatedData,
    currentPage,
    currentSize,
    currentSort,
    setCurrentPage,
    setSize,
    pagination,
    isLoading,
    setSort,
    reset: resetListData
  } = usePaginate<RealEstateLightDTO>({ infiniteScroll: false })

  const [markersData, setMarkersData] = useState<RealEstateLightDTO[]>([])

  const fetchData = useCallback(async () => {
    if (!filters) return
    const payload = {
      page: currentPage,
      limit: currentSize,
      sort: parseSortObject(currentSort),
      ...filters
    }

    const searchId = uuidv4()

    gtmEvent<UserSearch>('user_search', {
      max_distance: payload.maxDistance,
      lng: payload.coordinates[0],
      lat: payload.coordinates[1],
      sort: payload.sort,
      size_max: payload.size?.mtsMax,
      size_min: payload.size?.mtsMin,
      price_max: payload.price?.max,
      price_min: payload.price?.min,
      page: currentPage,
      limit: currentSize,
      contract_type: payload.contractType,
      group: payload.group,
      address_input: addressInputRef.current?.value || '',
      search_correlation_id: searchId
    })

    return fetchPaginated(async () => {
      const res = await fetch('/api/real-estate/filter', {
        method: 'POST',
        body: JSON.stringify(payload),
        next: { revalidate: 60 }
      })
      const result = (await res.json()) as {
        paginationResults: AggregatePaginateResult<RealEstateLightDTO>
        singularResults: RealEstateLightDTO[]
      }

      gtmEvent<SearchResultsMetadata>('search_results_metadata', {
        total_pages: result.paginationResults.totalPages,
        total_elements: result.paginationResults.totalDocs,
        search_correlation_id: searchId
      })

      setMarkersData(result.singularResults)
      return result.paginationResults
    })
  }, [filters, currentPage, currentSize, currentSort, fetchPaginated])

  useEffect(() => {
    // Current page triggers a fetch to retrieve the next items.
    void fetchData()
  }, [currentPage, fetchData])

  useLoadingToast(TOAST_ID, isLoading)

  const addressInputRef = useRef<HTMLInputElement | null>(null)

  const mobileFilters: FilterItem[] = useMemo(() => {
    return [
      {
        title: 'Tipo de transacción',
        content: (
          <RadiusSelector
            isDisabled={isLoading}
            fontSize="sm"
            enum={ContractType}
            value={contractTypeFilter === null ? '' : contractTypeFilter}
            onOptionSelection={handleContractTypeOptionChange}
          />
        )
      },
      {
        title: 'Tipo de propiedad',
        content: (
          <CheckboxSelector
            disableAll={isLoading}
            options={realEstateGroups}
            selected={groupFilter as RealEstateGroupKeys[]}
            handleStringCheckBoxes={setGroupFilter}
          />
        )
      },
      {
        title: 'Estado',
        content: (
          <CheckboxSelector
            disableAll={isLoading}
            options={states}
            selected={statesFilter}
            handleStringCheckBoxes={setStatesFilter}
          />
        )
      },
      {
        title: 'Tamaño',
        content: (
          <SizeFilterWrapper
            disableAll={isLoading}
            sizeFilter={sizeFilter}
            dispatch={dispatchSizeFilter}
          />
        )
      },
      {
        title: 'Precio',
        content: (
          <PriceFilterWrapper
            disableAll={isLoading}
            priceFilter={priceFilter}
            dispatch={dispatchPriceFilter}
          />
        )
      }
    ]
  }, [
    isLoading,
    contractTypeFilter,
    handleContractTypeOptionChange,
    realEstateGroups,
    groupFilter,
    setGroupFilter,
    states,
    statesFilter,
    setStatesFilter,
    sizeFilter,
    dispatchSizeFilter,
    priceFilter,
    dispatchPriceFilter
  ])

  const handleOnReset = useCallback(() => {
    gtmEvent<UserSearchResetClick>('user_search_reset_click', {})

    setMarkersData([])
    resetListData()
    resetSelectedGroups()
    resetSelectedStates()
    setContractTypeFilter(null)
    dispatchSizeFilter({
      type: ReducerActionType.Set,
      payload: { mtsMin: AllowedSizes.NoValue, mtsMax: AllowedSizes.NoValue }
    })
    dispatchPriceFilter({
      type: ReducerActionType.Set,
      payload: { min: AllowedPrices.NoValue, max: AllowedPrices.NoValue }
    })
    dispatchMapInfo({
      type: ReducerActionType.Set,
      payload: {
        lng: MEXICO_CITY_COORDINATES[0],
        lat: MEXICO_CITY_COORDINATES[1],
        radius: isMobile ? DEFAULT_RADIUS : null
      }
    })
    isProgrammaticZoomUpdate.current = true
    setZoom(PlaceType.Country)

    if (addressInputRef.current) {
      addressInputRef.current.value = ''
    }
  }, [
    dispatchMapInfo,
    dispatchPriceFilter,
    dispatchSizeFilter,
    isMobile,
    resetListData,
    resetSelectedGroups,
    resetSelectedStates,
    setContractTypeFilter
  ])

  const handleOnZoomUpdate = useCallback((z: number) => {
    if (isProgrammaticZoomUpdate.current) {
      isProgrammaticZoomUpdate.current = false
      return
    }

    gtmEvent<MapInteraction>('map_interaction', { type: 'zoom', zoom_level: z })
    setZoom(z)
  }, [])

  const handleOnMapDrag = useCallback(() => {
    gtmEvent<MapInteraction>('map_interaction', { type: 'drag' })
  }, [])

  const [listHasReachedBottom, setListHasReachedBottom] = useState(true)

  const isButtonTogglerVisible = useMemo(() => {
    if (showMap) {
      return true
    }

    if (pagination.totalPages === 1) {
      return true
    }
    return listHasReachedBottom
  }, [listHasReachedBottom, pagination.totalPages, showMap])

  useEffect(() => {
    if (statesFilter.length > 0) {
      isProgrammaticZoomUpdate.current = true
      setZoom(PlaceType.Country)

      dispatchMapInfo({
        type: ReducerActionType.Set,
        payload: {
          lng: MEXICO_CITY_COORDINATES[0],
          lat: MEXICO_CITY_COORDINATES[1],
          radius: null
        }
      })
    }
  }, [statesFilter, dispatchMapInfo])

  return (
    <VStack divider={<StackDivider />} spacing={0} w="full" position="relative">
      <Button
        display={isMobile ? 'flex' : 'none'}
        opacity={isButtonTogglerVisible ? 1 : 0}
        transition="opacity 0.3s ease-in-out"
        color="text.invert"
        leftIcon={<Icon boxSize={6} as={showMap ? FaThList : IoMdMap} color="text.invert" />}
        bg="primary.400"
        zIndex="banner"
        bottom={`calc(2.5% + env(safe-area-inset-bottom))`}
        rounded="md"
        position="fixed"
        left="50%"
        transform="translateX(-50%)"
        onClick={toggleMap}>
        {showMap ? 'Lista' : 'Mapa'}
      </Button>
      <Suspense>
        <ParamsUpdater filters={filters} />
      </Suspense>
      <Container
        as={HStack}
        flexDirection="row"
        px={4}
        py={2}
        maxW="container.xl"
        align="center"
        p={4}
        w="full"
        h={{ base: 'auto', md: SEARCHBAR_HEIGHT }}>
        <AddressSearch
          inputRef={addressInputRef}
          isDisabled={isLoading}
          flex={1}
          minW={{ base: 200, md: 350 }}
          center={mapInfo}
          onUpdate={(payload) => {
            dispatchMapInfo({
              type: ReducerActionType.Set,
              payload: {
                lat: payload.lat,
                lng: payload.lng,
                radius: !showMap ? getPlaceTypeRadius(payload.type) : null
              }
            })
            if (payload.type) {
              const type: PlaceType = payload.type
              setZoom(type)
            }
          }}
        />
        <SearchFiltersDrawer
          display={isMobile ? 'flex' : 'none'}
          filters={mobileFilters}
          onReset={handleOnReset}
          totalItems={pagination.totalDocs}
        />

        <Flex display={isMobile ? 'none' : 'flex'} overflow="hidden" w="full" alignItems="stretch">
          <ScrollDragContainer mouseScroll={false} w="full">
            <HStack w="full">
              <Dropdown
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                minW={180}
                placeholder="Tipo de transacción"
                enum={ContractType}
                value={contractTypeFilter === null ? '' : contractTypeFilter}
                onOptionSelection={handleContractTypeOptionChange}
              />
              <MultiSelectDropdown
                placeholder="Tipo de propiedad"
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                maxW="40vw"
                minW={180}
                options={realEstateGroups}
                selected={groupFilter as RealEstateGroupKeys[]}
                handleStringCheckBoxes={setGroupFilter}
              />
              <MultiSelectDropdown
                placeholder="Estado"
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                maxW="40vw"
                minW={180}
                options={states}
                selected={statesFilter}
                handleStringCheckBoxes={setStatesFilter}
              />
              <SizePopoverFilterWrapper
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                minW={180}
                sizeFilter={sizeFilter}
                dispatch={dispatchSizeFilter}
              />
              <PricePopoverFilterWrapper
                isDisabled={isLoading}
                fontSize="sm"
                bg="background.surface"
                rounded="full"
                minW={180}
                priceFilter={priceFilter}
                dispatch={dispatchPriceFilter}
              />

              <ToolboxMenuItem
                minW="auto"
                w={10}
                h={10}
                label={'Más'}
                icon={TfiMoreAlt}
                aria-label={'más'}
                bg="background.surface"
                p={2}
                color="primary"
                groups={[
                  {
                    title: 'Más acciones',
                    id: 'actions',
                    items: [
                      {
                        id: 'delete-filters',
                        label: 'Borrar filtros',
                        isActive: false,
                        action: handleOnReset
                      },
                      {
                        id: 'toggle-map',
                        label: showMap ? 'Esconder mapa' : 'Mostrar mapa',
                        isActive: false,
                        action: toggleMap
                      }
                    ]
                  }
                ]}
              />
            </HStack>
          </ScrollDragContainer>
        </Flex>
      </Container>

      <HStack h="full" w="full" align="flex-start" spacing={0}>
        <Box
          flex={showMap ? { base: '1', md: '0.5' } : '0'}
          h={CONTENT_HEIGHT}
          overflow={showMap ? 'initial' : 'hidden'}
          visibility={showMap ? 'visible' : 'hidden'}
          w="full"
          display={showMap ? 'block' : 'none'}
          position="relative">
          <MapWithMarkers
            initialCoordinates={initialFilters?.coordinates as MapLocation}
            initialRadius={initialFilters?.maxDistance}
            zoom={zoom}
            onZoomUpdate={handleOnZoomUpdate}
            isMapInteractive={!isLoading}
            items={markersData || []}
            center={mapInfo}
            onDragEnd={handleOnMapDrag}
            onUpdate={handleMapUpdate}
          />
        </Box>
        <Box
          style={{ WebkitOverflowScrolling: 'touch' }}
          bg="background.surface"
          h={CONTENT_HEIGHT}
          overflowY={isMobile && showMap ? 'hidden' : 'auto'}
          flex={{ base: showMap ? '0' : '1', md: showMap ? '0.5' : '1' }}>
          <Suspense fallback={<Text>Lista de propiedades</Text>}>
            <RealEstateList
              hasReachedBottom={(reached) => setListHasReachedBottom(!reached)}
              isMobile={isMobile}
              showMap={showMap}
              onPageSize={(v) => {
                if (v) {
                  setSize(parseInt(PageSizeOptions[v]))
                }
              }}
              onSort={(v) => {
                const dict = {
                  [RealEstateSortOptions.Closest]: { distance: 1 },
                  [RealEstateSortOptions.SaleHighToLow]: { 'price.sale.price': -1 },
                  [RealEstateSortOptions.SaleLowToHigh]: { 'price.sale.price': 1 },
                  [RealEstateSortOptions.Latest]: { id: -1 }
                }
                if (v) {
                  setSort(dict[RealEstateSortOptions[v]])
                }
              }}
              onReset={handleOnReset}
              items={paginatedData}
              totalItems={pagination.totalDocs}
              page={currentPage}
              totalPages={pagination.totalPages}
              setPage={setCurrentPage}
              isLoading={isLoading}
            />
          </Suspense>
        </Box>
      </HStack>
    </VStack>
  )
}

export default HomepageSearch
