import { useSetReset } from '@/hooks/useSetReset'
import {
  AllowedPrices,
  AllowedSizes,
  ContractType,
  FilterRealEstateParams,
  MapLocation,
  RealEstateGroup
} from '@/core/real-estate/typings'
import { useCallback, useMemo, useState } from 'react'
import { getEnumKeyByValue } from '@/utils/get-enum-key-by-value'
import { MEXICAN_STATES, MEXICO_CITY_COORDINATES } from '@/constants'
import { propIfExists } from '@/utils/prop-if-exists'
import { MapCenter } from '@/core/typings'
import useCheckboxes from '@/hooks/useCheckboxes'

export type RealEstateGroupKeys = keyof typeof RealEstateGroup

export const useFilters = ({
  initialFilters,
  mapInfo
}: {
  initialFilters: FilterRealEstateParams | null
  mapInfo: MapCenter & { radius: number | null }
}) => {
  const [sizeFilter, dispatchSizeFilter] = useSetReset<{
    mtsMin: AllowedSizes
    mtsMax: AllowedSizes
  }>({
    mtsMin: initialFilters?.size?.mtsMin
      ? (initialFilters.size.mtsMin.toString() as AllowedSizes)
      : AllowedSizes.NoValue,
    mtsMax: initialFilters?.size?.mtsMax
      ? (initialFilters.size.mtsMax.toString() as AllowedSizes)
      : AllowedSizes.NoValue
  })

  const [priceFilter, dispatchPriceFilter] = useSetReset<{
    min: AllowedPrices
    max: AllowedPrices
  }>({
    min: initialFilters?.price?.min
      ? (initialFilters.price.min.toString() as AllowedPrices)
      : AllowedPrices.NoValue,
    max: initialFilters?.price?.max
      ? (initialFilters.price.max.toString() as AllowedPrices)
      : AllowedPrices.NoValue
  })

  const [contractTypeFilter, setContractTypeFilter] = useState<keyof typeof ContractType | null>(
    initialFilters?.contractType
      ? getEnumKeyByValue(ContractType, initialFilters.contractType) || null
      : null
  )

  const mappedGroups = useMemo(
    () =>
      initialFilters?.group
        ? initialFilters.group
            .map((g) => getEnumKeyByValue(RealEstateGroup, g) || '')
            .filter((g) => g.length)
        : [],
    [initialFilters?.group]
  )

  const {
    selected: groupFilter,
    handleStringCheckBoxes: setGroupFilter,
    resetSelected: resetSelectedGroups
  } = useCheckboxes(mappedGroups)

  const {
    selected: statesFilter,
    handleStringCheckBoxes: setStatesFilter,
    resetSelected: resetSelectedStates
  } = useCheckboxes(initialFilters?.states || [])

  const filters: FilterRealEstateParams | null = useMemo(() => {
    const radius = mapInfo.radius ?? initialFilters?.maxDistance
    if (!radius) return null

    return {
      maxDistance: radius,
      coordinates: [
        mapInfo.lng ?? initialFilters?.coordinates?.[0] ?? MEXICO_CITY_COORDINATES[0],
        mapInfo.lat ?? initialFilters?.coordinates?.[1] ?? MEXICO_CITY_COORDINATES[1]
      ] as MapLocation,
      ...propIfExists(
        'contractType',
        contractTypeFilter ? ContractType[contractTypeFilter] : undefined
      ),
      ...propIfExists(
        'group',
        groupFilter?.length
          ? (groupFilter as RealEstateGroupKeys[]).map((i) => RealEstateGroup[i])
          : undefined
      ),
      size: {
        mtsMin: parseInt(sizeFilter.mtsMin),
        mtsMax: parseInt(sizeFilter.mtsMax)
      },
      price: {
        min: parseInt(priceFilter.min),
        max: parseInt(priceFilter.max)
      },
      ...propIfExists('states', statesFilter?.length ? statesFilter : undefined)
    }
  }, [
    mapInfo.radius,
    mapInfo.lng,
    mapInfo.lat,
    initialFilters?.maxDistance,
    initialFilters?.coordinates,
    contractTypeFilter,
    groupFilter,
    sizeFilter.mtsMin,
    sizeFilter.mtsMax,
    priceFilter.min,
    priceFilter.max,
    statesFilter
  ])

  const states = MEXICAN_STATES.map((state) => ({ value: state, label: state }))

  const realEstateGroups = Object.keys(RealEstateGroup).map((key) => ({
    value: key as RealEstateGroupKeys,
    label: RealEstateGroup[key as keyof typeof RealEstateGroup]
  }))

  const handleContractTypeOptionChange = useCallback((value: keyof typeof ContractType | null) => {
    setContractTypeFilter(value)
  }, [])

  return {
    filters,
    states,
    dispatchSizeFilter,
    dispatchPriceFilter,
    setContractTypeFilter,
    setGroupFilter,
    setStatesFilter,
    resetSelectedStates,
    resetSelectedGroups,
    statesFilter,
    realEstateGroups,
    contractTypeFilter,
    groupFilter,
    sizeFilter,
    priceFilter,
    handleContractTypeOptionChange
  }
}
