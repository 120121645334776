import { useSearchParams } from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { searchParamsToObject } from '@/utils/search-params-to-obj'
import { createInitialFilters } from '@/utils/create-initial-filters'

export const useInitialFilters = () => {
  const searchParams = useSearchParams()

  const factory = useCallback(() => {
    const spObj = searchParamsToObject(searchParams)
    return createInitialFilters(spObj)
  }, [searchParams])

  return useMemo(() => factory(), [factory])
}