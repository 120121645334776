import { BrokerDoc } from '@/core/broker/model'
import { RealEstateAttrs } from '@/core/real-estate/model'
import { MediaDTO } from '@/core/media/typings'
import { BrokerDTO } from '@/core/broker/typings'
import { SpaceDTO } from '@/core/space/typings'

export enum RealEstateVariant {
  Office = 'Oficina',
  Commercial = 'Comercial',
  CommercialSpaces = 'Espacios Comerciales',
  ShoppingPlaza = 'Plaza Comercial',
  CommercialPlot = 'Terreno Comercial',
  ShoppingCenter = 'Centro Comercial',
  CommercialPremises = 'Local Comercial',
  IndustrialProperty = 'Propiedad Industrial',
  IndustrialWarehouse = 'Nave Industrial',
  Warehouse = 'Bodega',
  IndustrialWarehouses = 'Naves Industriales',
  DistributionCenter = 'Centro de Distribución',
  Plot = 'Terreno',
  Building = 'Edificio',
  ResidentialTouristicHotel = 'Residencial, Turístico, Hotelero',
  TouristicHotel = 'Turístico, Hotelero',
  Hotel = 'Hotel',
  Square = 'Plaza',
  CommercialSpace = 'Espacio Comercial',
  House = 'Casa',
  CommercialProject = 'Proyecto Comercial',
  PlotSpec = 'Terreno SPEC TO SUIT'
}

export enum RealEstateGroup {
  Offices = 'Oficinas',
  Retail = 'Retail',
  Industrial = 'Industrial',
  Plots = 'Terrenos',
  Investment = 'Inversión',
  Hotels = 'Hotelería'
}

export enum ContractType {
  Rent = 'Renta',
  Sell = 'Venta',
  Both = 'Renta/Venta',
  Sublease = 'Subarrendamiento'
}

export enum Currency {
  MXN = 'MXN',
  USD = 'USD',
  EUR = 'EUR'
}

export type Latitude = number
export type Longitude = number
export type MapLocation = [Longitude, Latitude]
export type GeoJSON = {
  type: 'Point'
  coordinates: MapLocation
}

export interface RealEstateIdentifier {
  realEstateId: string
}

export interface AddressDTO {
  street?: string
  neighborhood?: string
  postalCode?: string
  city?: string
  state?: string
  formattedAddress?: string
}

export interface Size {
  rentableM2?: number
  officeM2?: number
  plotM2?: number
  warehouseM2?: number
  totalConstructionM2?: number
}

export interface PriceDetail {
  currency?: Currency
  price?: number | null
  label?: string
}

export interface Price {
  rent?: PriceDetail
  sale?: PriceDetail
  maintenanceAmount?: string
}

export interface AdditionalData {
  realEstateClass?: string
  delivery?: string
  parkingSlots?: string
  plotUse?: string
  subMarket?: string
  floor?: string
  contractType?: ContractType
}

export interface RealEstateDTO {
  id: string
  name: string
  slug: string
  zoneId?: string
  changes?: string
  comments?: string
  available: boolean
  group: RealEstateGroup
  variant: RealEstateVariant
  address: AddressDTO
  size: Size
  price: Price
  additionalData: AdditionalData
  location: GeoJSON
  highlights?: string[]
  medias: MediaDTO[]
  brokers: BrokerDTO[]
  videoRepoUrl?: string
  spaces?: SpaceDTO[]
}

export interface RealEstateLightDTO {
  id: string
  name: string
  slug: string
  group: RealEstateGroup
  variant: RealEstateVariant
  location: GeoJSON
  address: AddressDTO
  subMarket?: string
  contractType?: ContractType
  size: Size
  price: Price
  highlights: string[]
  medias: MediaDTO[]
  brokers: BrokerDTO[]
}

export enum ExcelRealEstateFileColumns {
  'ID (ref)',
  'Slug (unico)',
  'ID de Zona',
  'Cambios',
  'Tipo',
  'Nombre',
  'Direccion',
  'Calle',
  'Colonia',
  'Ciudad',
  'Estado',
  'CP',
  'Corredor',
  'Coordenadas (lng, lat)',
  'Disponible',
  'Piso',
  'Cajones',
  'm2 Rentables (num)',
  'm2 Terreno (num)',
  'm2 Oficina (num)',
  'm2 Nave (num)',
  'm2 Construccion (num)',
  'Moneda Renta',
  'Etiqueta Precio Renta',
  'Precio Renta (num)',
  'Moneda Venta',
  'Etiqueta Precio Venta',
  'Precio Venta (num)',
  'Mantenimiento',
  'Uso de suelo',
  'Clase',
  'Entrega',
  'Broker (ref)',
  'Comentarios',
  'Highlights (multi)',
  'Aparece en website (bin)',
  'Repo Videos'
}

export interface RealEstateBatch {
  brokers: BrokerDoc[]
  attrs: RealEstateAttrs
}

export interface FilterRealEstateParams {
  maxDistance: number
  coordinates: MapLocation
  contractType?: ContractType
  group?: RealEstateGroup[]
  size?: {
    mtsMin: number
    mtsMax: number
  }
  price?: {
    min: number
    max: number
  }
  states?: string[]
}

export enum RealEstateSortOptions {
  Closest = 'Más cercano',
  SaleHighToLow = 'Venta (precio más alto)',
  SaleLowToHigh = 'Venta (precio más bajo)',
  Latest = 'Actualizado recientemente'
}

export enum PageSizeOptions {
  Ten = '10',
  TwentyFive = '25',
  Fifty = '50',
  Hundred = '100',
  FiveHundred = '500'
}

export enum AllowedPrices {
  NoValue = '0',
  Price1 = '10000',
  Price2 = '30000',
  Price3 = '50000',
  Price4 = '100000',
  Price5 = '200000',
  Price6 = '500000',
  Price7 = '1000000',
  Price8 = '2000000',
  Price9 = '5000000',
  Price10 = '10000000',
  Price11 = '50000000',
  Price12 = '100000000',
  Price13 = '300000000'
}

export enum AllowedSizes {
  NoValue = '0',
  Size1 = '100',
  Size2 = '300',
  Size3 = '500',
  Size4 = '1000',
  Size5 = '2000',
  Size6 = '5000',
  Size7 = '10000',
  Size8 = '20000',
  Size9 = '50000',
  Size10 = '100000',
  Size11 = '150000'
}

export interface NavigationListElement {
  slug: string
}
